import React from "react"
import { graphql } from "gatsby"
import { Image } from "@atoms/Image"
// import Prism from '@atoms/Prism'
import SEO from "@atoms/Seo"
import * as Spacer from "@atoms/Spacer"
import { Section, SectionHeader, SectionContent, SectionColumn, SectionMoreLink } from "@layouts/Section"

import * as css from "@css/layouts/l-article.module.styl"

// import { gsap } from "gsap"
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
// gsap.registerPlugin(ScrollTrigger)

function NewsPage({data})
{
    console.log(data)
    const { news } = data
    const { title, date, content, thumbnail } = news

    // const img = getImage(thumbnail.gatsbyImageData)
    // const contentRef = React.useRef()
    console.log(thumbnail)
    return(
        <>
            <SEO
                subtitle={title}
                description={content}
                image={thumbnail && `${thumbnail.node.localFile.publicURL}`}
            />
            <Spacer.Header/>
            <Section>
                <SectionHeader
                    title="News"
                    text="ニュース"
                    isBorder={false}
                />
                <article className={css.lArticle}>
                    <div className={css.lArticle__inner}>
                        <header className={css.lArticle__header}>
                            <p className={css.aDate}><time dateTime={date}>{date}</time></p>
                            <h1 className={css.aTitle}>{title}</h1>
                        </header>
                        { thumbnail &&
                            <figure className={css.lArticle__thumbnail}>
                                <Image data={thumbnail.node.localFile} alt="thubmanil" />
                            </figure>
                        }
                        <div className={css.lArticle__content} dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                </article>
                <SectionMoreLink
                    title="More"
                    text="ニュース一覧"
                    to="/news/"
                    isBorder={false}
                />
            </Section>
        </>
    )
}

export const pagerQuery = graphql`
    fragment NewsThumbnail on ImageSharp {
        gatsbyImageData(
            width: 1280
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
        )
    }
    fragment News on WpNews {
        acf_news {
            category
        }
        slug
        date(formatString: "YYYY.MM.DD")
        title
        content
        uri
        databaseId
        thumbnail: featuredImage {
            node {
                localFile {
                    publicURL
                    childImageSharp {
                        gatsbyImageData(
                            width: 1280
                            placeholder: BLURRED
                            formats: [AUTO, WEBP]
                            quality: 100
                        )
                    }
                }
            }
        }
    }
    query NewsBySlug($id: String!){
        news : wpNews( id: { eq: $id }){
            ...News
        }
    }
`
export default NewsPage